import { useState } from "react";
async function sendFormToTelegram(message) {
  const botToken = "5864071566:AAF_BSzGkpN496SBrpfcLNZmczGxii1sNT4";
  const chatId = "-914225153";

  const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: chatId,
        text: message,
      }),
    });

    if (response.ok) {
      console.log("Form was send!");
    } else {
      throw new Error("Failed to submit form");
    }
  } catch (error) {
    console.error("Error submitting form:", error);
  }
}
function sendInfo(e, closeModal, setIsOpen) {
  const tel = document.querySelector(".modal-tel-input").value;
  const string = `Проект: MixHouse \n Телефон: ${tel}`;
  e.preventDefault();
  if (tel.trim() === "") {
    console.log("Please fill in all the fields");
    return;
  }
  sendFormToTelegram(string);
  document.querySelector(".modal-tel-input").value = "";
  setTimeout(closeModal(setIsOpen), 1000);
}

const Modal = ({ isOpen, setIsOpen }) => {
  const [isClosing, setIsClosing] = useState(false);

  const closeModal = () => {
    document.body.classList.remove("modal-open");
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 300);
  };

  return (
    <div className={`modal-wrapper ${isOpen ? "modal-open" : ""}`}>
      {isOpen && (
        <div className={`modal-overlay ${isClosing ? "modal-closing" : ""}`}>
          <div className="modal-content">
            <span className="modal-close" onClick={closeModal}>
              &#10006;
            </span>
            <h1 className="modal-h">Потрібна консультація?</h1>
            <p className="modal-text" align="center">
              Заповніть форму нижче і ми зв'яжемося з вами найближчим часом.
            </p>
            <form
              onSubmit={(e) => sendInfo(e, closeModal, setIsOpen)}
              className="modal-form"
            >
              <input
                className="modal-tel-input"
                placeholder="Номер телефону*"
              ></input>
              <button className="form-btn" id="modal-btn" type="submit">
                Відправити
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
