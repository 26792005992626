import Slider from "./Slider";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
const Materials = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div
      ref={ref}
      className={`materials-block animated-element ${
        isAnimated ? "animate" : ""
      }`}
    >
      <h1 className="material-h" align="center">
        Фото-галерея виконаних робіт
      </h1>
      <Slider></Slider>
      <div className="mat-wrap">
        <p className="material-p">
          Одним із ключових аспектів нашої продукції є використання якісного
          матеріалу, що гарантує міцність, довговічність та естетичний вигляд
          дверей. Наші двері виготовляються з високоякісних матеріалів, які
          відповідають найвищим стандартам якості.
        </p>
      </div>
    </div>
  );
};
export default Materials;
