import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import Img1 from "../img/darumi.jpg";
import Img2 from "../img/faac.jpg";
import Img3 from "../img/mk-door.jpg";
import Img4 from "../img/terminus.jpg";
import Img5 from "../img/termoplast.jpg";
import Img6 from "../img/setto.jpg";
import Img7 from "../img/stil-doors.jpg";
const images = [Img1, Img2, Img3, Img4, Img5, Img6, Img7];
const links = [
  "https://drive.google.com/file/d/1nK1071Had9RoP9cWZERV67WML73xoO2l/view?usp=sharing",
  "https://drive.google.com/file/d/1doVDoQaBDKrIALvYiAcKvQYEB8ur0fiM/view?usp=sharing",
  "https://drive.google.com/file/d/1xUT_PVhEcZv_aV2SMeT_n_nopJShF_UG/view?usp=sharing",
  "https://drive.google.com/file/d/1zRVAJVghXtBw0aifIBdv9sI9dYU7sbbG/view?usp=sharing",
  "https://drive.google.com/file/d/14f8drZxL5KSroBkU9WUUheIxxHqjC2g4/view?usp=sharing",
  "https://drive.google.com/file/d/1fYNj6ZZm53pmu2lNAGzIG-rkBCBi5V8M/view?usp=sharing",
  "https://drive.google.com/file/d/17qJ8FxUA5HcJyHvcyu3-M9F6GpBEDuC2/view?usp=sharing",
];
const types = [
  "Вхідні двері",
  "Вхідні двері",
  "Вхідні двері",
  "Міжкімнатні двері",
  "Міжкімнатні двері",
  "Міжкімнатні двері",
  "Ворота-автоматика",
];
const CatSlider = ({ setType }) => {
  return (
    <div className="slider-container">
      <Splide
        options={{
          direction: "ltr", // 'ttb' means top-to-bottom
          height: "100%", // Set your desired slider height
          perPage: 1, // Number of slides to display per view
          gap: "0px", // Adjust the gap between slides as needed
          type: "loop", // For continuous looping
          autoplay: true, // Enable auto-play
          interval: 3000,
          pagination: true,
          arrows: true, // Remove next/prev arrow
          drag: true, // Enable drag navigation
          scrollbar: true, // Enable the scrollbar
          dragScrollbar: true, // Enable the scrollbar to be dragged
          scrollbarOptions: {
            autoHide: true, // Auto hide the scrollbar when not scrolling
          },
        }}
      >
        {images.map((image, index) => (
          <SplideSlide key={index} className="slide-cat">
            <a
              className="slide-wrap"
              href={links[index]}
              target="_blank"
              rel="noreferrer"
              onMouseOver={() => setType(types[index])}
            >
              <img
                style={{ width: "80%" }}
                className="cat-img"
                src={image}
                alt={`katalog ${index + 1}`}
              />
            </a>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default CatSlider;
