import "./App.css";
import { useEffect, useState } from "react";
import Top from "./img/Group 57.png";
import Ask from "./img/Group 63.png";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Materials from "./Components/Materials";
import Navbar from "./Components/Navbar";
import Welcome from "./Components/Welcome";
import WhyUs from "./Components/WhyUs";
import Modal from "./Components/Modal";
import Production from "./Components/Production";
import BurgerMenu from "./Components/BurgerMenu";
import Catalog from "./Components/Catalog";

function toTop() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0;
}
const openModal = (setIsOpen) => {
  setIsOpen(true);
  document.body.classList.add("modal-open");
};
function hideBurger(e) {
  if (!document.querySelector(".burger-menu").contains(e.target)) {
    document.querySelector(".burger-menu").classList.remove("show");
  }
}
// preloader
window.onload = function () {
  document.body.classList.add("loaded_hiding");
  window.setTimeout(function () {
    document.body.classList.add("loaded");
    document.body.classList.remove("loaded_hiding");
  }, 500);
};
function App() {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        document.querySelector(".toTop").classList.add("icon-show");
        document.querySelector(".ask").classList.add("icon-show");
      } else {
        document.querySelector(".toTop").classList.remove("icon-show");
        document.querySelector(".ask").classList.remove("icon-show");
      }
      if (window.scrollY > 300) {
        document.querySelector(".toTop").classList.add("anim");
        document.querySelector(".ask").classList.add("anim");
      } else {
        document.querySelector(".toTop").classList.remove("anim");
        document.querySelector(".ask").classList.remove("anim");
      }
    });
  }, []);
  return (
    <div className="App">
      <div className="preloader">
        <div className="preloader__row">
          <div className="preloader__item"></div>
          <div className="preloader__item"></div>
        </div>
      </div>
      <Navbar></Navbar>
      <div onClick={(e) => hideBurger(e)}>
        <BurgerMenu></BurgerMenu>
        <img
          alt="Top"
          className="toTop"
          onClick={(e) => toTop()}
          src={Top}
        ></img>
        <img
          alt="Ask"
          className="ask"
          onClick={(e) => openModal(setIsOpen)}
          src={Ask}
        ></img>
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}></Modal>
        <Welcome></Welcome>
        <WhyUs></WhyUs>
        <Production></Production>
        <Materials></Materials>
        <Catalog></Catalog>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
