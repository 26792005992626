import React from "react";

const Map = () => {
  return (
    <iframe
      title="google map"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9995.836377880005!2d24.6982728!3d51.2198292!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6c6c283ded6fa8cc!2zTWl4IEhvdXNlLiDQktGW0LrQvdCwINC00LLQtdGA0ZY!5e0!3m2!1suk!2sua!4v1666073856837!5m2!1suk!2sua"
      className="map"
      allowFullScreen=""
      loading="lazy"
    ></iframe>
  );
};
export default Map;
