import CatSlider from "./CatSlider";
import VerticalSlider from "./VerticalSlider";
import { useState } from "react";

const SliderCat = () => {
  const [type, setType] = useState("Вхідні двері");
  return (
    <div className="slider-div row">
      {/* <img className="col-lg-7 col-sm-7 catalog"></img> */}
      <CatSlider className="col-lg-10" setType={setType}></CatSlider>
      {/* <div className="col-lg-3 col-sm-3 logo-slider">
        <p className="type">
          Тип: <b className="type changed-type">{type}</b>
        </p>
        <VerticalSlider type={type} setType={setType}></VerticalSlider>
      </div> */}
    </div>
  );
};

export default SliderCat;
