import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Img1 from "../img/taras1.jpg";
import Img2 from "../img/taras2.jpg";
import Img3 from "../img/col1.jpg";
import Img4 from "../img/col2.jpg";
import Img5 from "../img/col3.jpg";
import Img6 from "../img/ex3.jpg";
import Img7 from "../img/exx4.jpg";
import Img8 from "../img/ex5.jpg";
import Img9 from "../img/1.jpg";
import Img10 from "../img/2.jpg";
import Img11 from "../img/3.jpg";
import ImgModal from "./ImgModal";

const Slider = () => {
  const swiperRef = useRef(null);
  const [zoomedIndex, setZoomedIndex] = useState(null);
  const handleImageClick = (index) => {
    setZoomedIndex(index);
  };

  const handleCloseModal = () => {
    setZoomedIndex(null);
  };
  const handleSlideChange = () => {
    if (swiperRef.current && swiperRef.current.controller) {
      swiperRef.current.controller.setTranslate(0, 0); // Reset translations
      swiperRef.current.controller.setTransition(0); // Reset transitions
      swiperRef.current.slides.forEach((slide, index) => {
        const scale = index === swiperRef.current.activeIndex ? 1 : 0.3; // Scale down the non-active slides
        slide.style.transform = `scale(${scale})`;
      });
    }
  };

  return (
    <>
      <Swiper
        className="swiper-mat"
        modules={[Pagination]}
        spaceBetween={50}
        slidesPerView={3}
        centeredSlides={true}
        navigation
        pagination={{
          clickable: true,
        }}
        loop
        centeredSlidesBounds={true}
        onSlideChange={handleSlideChange} // Use the custom event handler
        onSwiper={(swiper) => (swiperRef.current = swiper)} // Save the swiper instance in ref
        effect={"coverflow"} // Use coverflow effect for better visual presentation
        coverflowEffect={{
          rotate: 0,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
      >
        <SwiperSlide>
          <img src={Img7} alt="Slide 1" onClick={() => handleImageClick(7)} />
        </SwiperSlide>
        <SwiperSlide>
          <div className="collage-wrapper" id="taras">
            <div className="collage-img t" id="taras-img">
              <img
                src={Img10}
                onClick={() => handleImageClick(10)}
                alt="Slide 1"
              />
            </div>

            <div className="collage-img tr" id="taras-img">
              <img
                src={Img11}
                alt="Slide 1"
                onClick={() => handleImageClick(11)}
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="collage-wrapper">
            <div
              className="collage-img c-img"
              onClick={() => handleImageClick(0)}
            >
              <img id="main-img" src={Img3} alt="Slide 1" />
            </div>
            <div>
              <div
                className="collage-img c-img"
                onClick={() => handleImageClick(1)}
              >
                <img src={Img4} alt="Slide 1" />
              </div>
              <div
                className="collage-img c-img"
                onClick={() => handleImageClick(2)}
              >
                <img src={Img5} alt="Slide 1" />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Img6} alt="Slide 1" onClick={() => handleImageClick(6)} />
        </SwiperSlide>
        <SwiperSlide>
          <div className="collage-wrapper" id="taras">
            <div className="collage-img" id="taras-img">
              <img
                src={Img1}
                onClick={() => handleImageClick(3)}
                alt="Slide 1"
              />
            </div>

            <div className="collage-img" id="taras-img">
              <img
                src={Img2}
                alt="Slide 1"
                onClick={() => handleImageClick(4)}
              />
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <img src={Img8} alt="Slide 1" onClick={() => handleImageClick(8)} />
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="collage-wrapper">
            <div className="collage-img c-img">
              <img
                id="main-img"
                src={Img3}
                alt="Slide 1"
                onClick={() => handleImageClick(0)}
              />
            </div>
            <div>
              <div className="collage-img c-img">
                <img
                  src={Img4}
                  alt="Slide 1"
                  onClick={() => handleImageClick(1)}
                />
              </div>
              <div className="collage-img c-img">
                <img
                  src={Img5}
                  alt="Slide 1"
                  onClick={() => handleImageClick(2)}
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="collage-wrapper" id="taras">
            <div className="collage-img" id="taras-img">
              <img
                src={Img1}
                onClick={() => handleImageClick(3)}
                alt="Slide 1"
              />
            </div>

            <div className="collage-img" id="taras-img">
              <img
                src={Img2}
                alt="Slide 1"
                onClick={() => handleImageClick(4)}
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      {zoomedIndex !== null && (
        <ImgModal
          imageUrl={
            zoomedIndex === 0
              ? Img3
              : zoomedIndex === 1
              ? Img4
              : zoomedIndex === 2
              ? Img5
              : zoomedIndex === 3
              ? Img1
              : zoomedIndex === 4
              ? Img2
              : zoomedIndex === 6
              ? Img6
              : zoomedIndex === 7
              ? Img7
              : zoomedIndex === 8
              ? Img8
              : zoomedIndex === 10
              ? Img10
              : zoomedIndex === 11
              ? Img11
              : Img8
          }
          onClose={handleCloseModal}
          id={
            zoomedIndex === 0
              ? "three"
              : zoomedIndex === 1
              ? "three"
              : zoomedIndex === 2
              ? "three"
              : zoomedIndex === 3
              ? "two"
              : zoomedIndex === 4
              ? "two"
              : zoomedIndex === 6
              ? "single"
              : zoomedIndex === 7
              ? "single"
              : zoomedIndex === 8
              ? "single"
              : "two"
          }
        />
      )}
    </>
  );
};

export default Slider;
