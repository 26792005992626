import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
const Welcome = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div className="welcome-block" id="welcome">
      <div
        ref={ref}
        className={`welcome-text animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <h5 className="welcome-h5">Креативний дизайн</h5>
        <h2 className="welcome-h2">Зроби свою оселю краще</h2>
        <p className="welcome-p">
          Шукаєте якісні вікна, двері, гаражні ворота?<br></br> Запрошуємо до
          салону-магазину "Mix House"!
        </p>
        <a href="#contact-block" className="welcome-btn">
          Залишити заявку &rarr;
        </a>
      </div>
    </div>
  );
};
export default Welcome;
