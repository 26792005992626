const BurgerMenu = () => {
  return (
    <div className="burger-menu">
      <div className="burger-links">
        <a
          className="nav-a"
          href="#welcome"
          onClick={(e) => {
            document.querySelector(".burger-menu").classList.remove("show");
          }}
        >
          Головна
        </a>
        <a
          className="nav-a"
          href="#whyUs"
          onClick={(e) => {
            document.querySelector(".burger-menu").classList.remove("show");
          }}
        >
          Про нас
        </a>
        <a
          className="nav-a"
          href="#catalog"
          onClick={(e) => {
            document.querySelector(".burger-menu").classList.remove("show");
          }}
        >
          Каталог
        </a>
        <a
          className="nav-a"
          href="#contact-block"
          onClick={(e) => {
            document.querySelector(".burger-menu").classList.remove("show");
          }}
        >
          Контакти
        </a>
      </div>
    </div>
  );
};
export default BurgerMenu;
