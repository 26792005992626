import Logo2 from "../img/logo_black.png";
import Burger from "../img/burger_icon.png";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbar-wrapper">
        <img alt="Mix House" src={Logo2} className="nav-logo"></img>
        <div className="nav-links">
          <a className="nav-a" href="#welcome">
            Головна
          </a>
          <a className="nav-a" href="#whyUs">
            Про нас
          </a>
          <a className="nav-a" href="#catalog">
            Каталог
          </a>
          <a className="nav-a" href="#contact-block">
            Контакти
          </a>
        </div>
        <img
          alt="menu"
          src={Burger}
          onClick={(e) => {
            document.querySelector(".burger-menu").classList.add("show");
          }}
          className="burger-icon"
        ></img>
      </div>
    </div>
  );
};

export default Navbar;
