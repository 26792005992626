import Arrow from "../img/red-arrow.png";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
const WhyUs = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div
      ref={ref}
      className={`whyUs-block animated-element ${isAnimated ? "animate" : ""}`}
      id="whyUs"
    >
      <h5 className="whyUs-h">Чому обирати саме нас?</h5>
      <div className="whyUs-row">
        <h1 className="col-lg-4 col-sm-4 col-4 whyUs-row-h">
          10+ років досвіду на ринку
        </h1>
        <div className="col-lg-4 col-sm-4 col-6 whyUs-row-block">
          <p className="whyUs-row-p">
            <img alt="arrow" src={Arrow} className="whyUs-arrow"></img>&nbsp;
            Професійну консультацію по групах товарів
          </p>
          <p className="whyUs-row-p">
            <img alt="arrow" src={Arrow} className="whyUs-arrow"></img>&nbsp;
            Гарантовану надійність та якість товарів підтверджену міжнародними
            нагородами та сертифікатами якості
          </p>
          <p className="whyUs-row-p">
            <img alt="arrow" src={Arrow} className="whyUs-arrow"></img>&nbsp;
            Широкий асортимент, який зможе задовільнити вибір кожного клієнта
          </p>
          <p className="whyUs-row-p">
            <img alt="arrow" src={Arrow} className="whyUs-arrow"></img>
            &nbsp;Якісний та професійний монтаж
          </p>
        </div>
      </div>
      <p className="whyUs-foot-p">
        Завдяки цьому ми маємо дуже багато позитивних відгуків та рекомендацій
        від наших клієнтів. Наша головна мета створити комфорт та безпеку для
        Ваших осель.
      </p>
    </div>
  );
};
export default WhyUs;
