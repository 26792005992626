import React from "react";
import PropTypes from "prop-types";

const ImgModal = ({ imageUrl, onClose, id }) => {
  return (
    <div className="image-modal" onClick={onClose}>
      <div className="image-modal-content" id={id}>
        <img src={imageUrl} alt="Modal" onClick={(e) => e.stopPropagation()} />
      </div>
    </div>
  );
};

ImgModal.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default ImgModal;
