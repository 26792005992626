import SliderCat from "./SliderCat";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
const Catalog = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div
      ref={ref}
      className={`catalog-block animated-element ${
        isAnimated ? "animate" : ""
      }`}
      id="catalog"
    >
      <h1 className="catalog-h">Каталог товарів</h1>
      <SliderCat></SliderCat>
    </div>
  );
};
export default Catalog;
