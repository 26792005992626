import Phone from "../img/phone.png";
import Mail from "../img/mail.png";
import Rect from "../img/Rectangle 128.png";
import Map from "./Map";
import Arrow from "../img/Arrow 3.png";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
async function sendFormToTelegram(message) {
  const botToken = "5864071566:AAF_BSzGkpN496SBrpfcLNZmczGxii1sNT4";
  const chatId = "-914225153";

  const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: chatId,
        text: message,
      }),
    });

    if (response.ok) {
      console.log("Form was send!");
    } else {
      throw new Error("Failed to submit form");
    }
  } catch (error) {
    console.error("Error submitting form:", error);
  }
}
function sendInfo(e) {
  const tel = document.querySelector("#tel").value;
  const name = document.querySelector("#name").value;
  const mail = document.querySelector("#mail").value;
  const string = `Проект: MixHouse \n Телефон: ${tel} \n Ім'я: ${name} \n Пошта: ${mail}`;
  e.preventDefault();
  if (tel.trim() === "" || name.trim() === "" || mail.trim() === "") {
    console.log("Please fill in all the fields");
    return;
  }
  sendFormToTelegram(string);
  document.querySelector("#tel").value = "";
  document.querySelector("#name").value = "";
  document.querySelector("#mail").value = "";
}
const Contact = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div
      ref={ref}
      className={`contact-block animated-element ${
        isAnimated ? "animate" : ""
      }`}
      id="contact-block"
    >
      <h1 className="contact-h">Зв'яжіться з нами</h1>
      <div className="contact-row">
        <h1 className="col-lg-5 col-sm-5 col-5 contact-row-h">
          Отримайте швидку консультацію, заповнивши форму
          <img alt="arrow" src={Arrow}></img>
        </h1>
        <form
          id="contact-form"
          className="col-lg-5 col-sm-5 col-5 contact-row-form"
        >
          <div className="form-wrap">
            <input
              type="text"
              className="contact-input"
              placeholder="Ваше ім'я*"
              required
              id="name"
            ></input>
            <input
              id="tel"
              type="text"
              className="contact-input"
              placeholder="Ваш номер телефону*"
              required
            ></input>
            <input
              id="mail"
              type="text"
              className="contact-input"
              placeholder="Ваш Email"
            ></input>
            <button
              type="submit"
              className="contact-form-btn"
              onClick={(e) => {
                sendInfo(e);
              }}
            >
              Відправити
            </button>
          </div>
        </form>
      </div>
      <p className="contact-or">Або</p>
      <div className="container">
        <div className="contact-hs">
          <h1 className="contacts-h col-lg-5 col-6">Контакти</h1>
          <div className="kovel-wrap-icon col-lg-5 col-6">
            <img className="rect" alt="R" src={Rect}></img>
            <div className="kovel-wrap">
              <h1 className="contacts-h">м.Ковель</h1>
              <h5 className="contacts-h5">вул.Сагайдачного</h5>
            </div>
          </div>
        </div>
        <div className="contacts-tels">
          <div className="contacts-p-wrap col-lg-5 col-sm-5 col-5">
            <a id="p" className="contacts-p" href="tel:+380503567868">
              <img className="contacts-icon" alt="Phone" src={Phone}></img>{" "}
              &nbsp;+38 (050) 356-78-68
            </a>
            <a id="p" className="contacts-p" href="tel:+380676491899">
              <img className="contacts-icon" alt="Phone" src={Phone}></img>{" "}
              &nbsp;+38 (067) 649-18-99
            </a>
            <a
              id="p"
              className="contacts-p"
              href="mailto:mixhousekovel@gmail.com"
            >
              <img className="contacts-icon" alt="Phone" src={Mail}></img>{" "}
              &nbsp;mixhousekovel@gmail.com
            </a>
          </div>
          <Map className="col-lg-5 col-sm-5 col-5"></Map>
        </div>
      </div>
    </div>
  );
};
export default Contact;
