const Footer = () => {
  return (
    <div className="footer">
      <p className="footer-p">
        Copyright MixHouse © 2023. All rights reserved. Created by{" "}
        <a href="https://www.side.systems/" target="_blank" rel="noreferrer">
          SiDE
        </a>
        .
      </p>
    </div>
  );
};
export default Footer;
