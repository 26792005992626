import Img1 from "../img/5.png";
import Img2 from "../img/4.png";
import Img3 from "../img/3.png";
import Img4 from "../img/2.png";
import Img5 from "../img/1.png";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
const Production = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div
      ref={ref}
      className={`prod-block animated-element ${isAnimated ? "animate" : ""}`}
    >
      <h1 className="prod-h">Наша продукція</h1>
      <div className="prod-row">
        <div className="rel">
          <img className="f-img" alt="4" src={Img1} />
          <div className="over">
            <p>Вхідні двері</p>
            <div className="over-wrap">
              <a
                className="over-links"
                href="https://drive.google.com/file/d/1fYNj6ZZm53pmu2lNAGzIG-rkBCBi5V8M/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                SETTO (Польща)
              </a>
              <a
                className="over-links"
                href="https://drive.google.com/file/d/14f8drZxL5KSroBkU9WUUheIxxHqjC2g4/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                Термопласт
              </a>
              <a
                className="over-links"
                href="https://drive.google.com/file/d/1xUT_PVhEcZv_aV2SMeT_n_nopJShF_UG/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                MK-DOOR (Польща)
              </a>
            </div>
          </div>
        </div>

        <div className="">
          <div className="col-lg-6 flex-row">
            <div className="rel">
              <img className="img-24 l" alt="4" src={Img2} />
              <div className="over over-2">
                <p>Міжкімнатні двері</p>
                <div className="over-wrap">
                  <a
                    className="over-links"
                    href="https://drive.google.com/file/d/1nK1071Had9RoP9cWZERV67WML73xoO2l/view?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Darumi
                  </a>
                  <a
                    className="over-links"
                    href="https://drive.google.com/file/d/17qJ8FxUA5HcJyHvcyu3-M9F6GpBEDuC2/view?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Stil Doors
                  </a>
                  <a
                    className="over-links"
                    href="https://drive.google.com/file/d/1zRVAJVghXtBw0aifIBdv9sI9dYU7sbbG/view?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terminus
                  </a>
                </div>
              </div>
            </div>

            <div className="rel">
              <img className="img-24 r" alt="4" src={Img4} />
              <div className="over over-4">
                <p>Вікна та Ролети</p>
                <div className="over-wrap">
                  <a
                    className="over-links"
                    href="https://drive.google.com/file/d/1ht_pONN9uYfH5p6abbtYFgsFAGUYUnT1/view?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Epsilon
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 flex-row">
            <div className="rel">
              <img className="img-35 l" alt="4" src={Img3} />
              <div className="over over-3">
                <p>Автоматика</p>
                <div className="over-wrap">
                  <a
                    className="over-links"
                    href="https://drive.google.com/file/d/1doVDoQaBDKrIALvYiAcKvQYEB8ur0fiM/view?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    FAAC
                  </a>
                </div>
              </div>
            </div>
            <div className="rel">
              <img className="img-35 r" alt="4" src={Img5} />
              <div className="over over-5">
                <p>Гаражні ворота</p>
                <div className="over-wrap">
                  <a
                    id="wisn"
                    className="over-links"
                    href=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    Wisniowski
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Production;
